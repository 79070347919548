/**
 * Brand Constants
 * Add your brand details here.
 */
export const siteConfig = {
	siteTitle: 'Remix SaaS',
	siteDescription:
		'A Lightweight, Production-Ready Remix Stack for your next SaaS Application.',
	siteUrl: 'https://remix-saas.fly.dev',
	siteImage: '/images/og-image.png',
	favicon: '/favicon.ico',
	twitterHandle: '@remix_saas',
	email: 'hello@remix-saas.run',
	address: '',
}
